@import '../../styles/abstracts/variables';

aside {
  display: flex;
  position: fixed;
  height: 100%;
  z-index: 1000;
  background: white;
  top: 0;
  right: 0;
  transition: .3s all ease-in-out;
  max-width: 350px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  // box-shadow: 0 0 20px 20px rgba(0,0,0,.3);
  //padding: 30px 20px;
  @media (max-width: $medium) {
    max-width: 100%;
  }

  label {
    font-size: 13px;
  }

  .aside__subcontainer {
    width: 350px;
    position: absolute;
    transition: .3s all;

    &:first-of-type {
      transform: translateX(0%);

      &.aside__subcontainer--hidden {
        transform: translateX(-100%);
      }
    }

    &--hidden {
      transform: translateX(100%);
    }
  }

  &.aside-container--hidden {
    transform: translateX(100%);
    box-shadow: none;
  }

  .btn--close {
    color: black;
    transition: .2s all;
    font-size: 15px;

    &:hover {
      color: rgba(170, 170, 170, 0.75) !important;
    }
  }

  h2 {
    color: black !important;
  }

  .sidebar-title {
    font-size: 20px;
  }

  a, p {
    font-size: 14px;
  }

  .sidebar-icon + p {
    margin-left: 5px;
  }

  .aside {
    &__line {
      transition: .2s all;
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      box-sizing: border-box;
      padding: 7px 0;

      &:hover {
        * {
          background-color: unset !important;
        }
      }

      a:hover {
        color: rgba(170,170,170,.75) !important;
      }

      &__line {
        background-color: black !important;
      }

      &--no-bg {
        justify-content: center;
      }

      &--narrow {
        width: fit-content !important;
        position: relative;
        z-index: 2;

        &:hover {
          color: #1d6cc1 !important;
        }
      }

      &:hover {
        color: rgba(170, 170, 170, 0.75) !important;

        // .aside__line--accordion {
        //   background-color: black !important;
        // }
      }

      &--accordion {
        display: block;
        box-shadow: none;
        border-radius: unset !important;
        margin: 0 !important;

        .aside__line {
          padding: unset;
        }

        .MuiAccordionSummary-root {
          min-height: unset;
        }

        .MuiAccordionSummary-content {
          margin: 0 !important;
        }

        .MuiButtonBase-root {
          padding: 0;
        }

        .angle-up {
          color: black;
        }

        &:hover {
          .angle-up {
            color: rgba(170, 170, 170, 0.75) !important;
          }
        }

        .MuiAccordionDetails-root {
          padding: 0;
        }

        &:hover {
          a {
            //color: white;
          }
        }
      }

      &:not(.aside__line--no-bg):hover {
        // background-color: #aaaaaa;
        //color: white;
      }
    }

    &__line-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding: 7px 0;

      .nav__submenu-item {
        box-sizing: border-box;
        padding: 7px 20px;
        a {
          width: 100%;
          display: block;
        }

        &:hover {
          cursor: pointer;
          //background-color: lightgray;
        }
      }

      &:first-of-type {
        justify-content: center;

        .btn {
          position: absolute;
          left: 20px;
        }
      }

      a {
        color: black;

        &:not(:first-of-type) {
          //margin-top: 7px;
        }
      }

      &--column {
        flex-direction: column;
        align-items: flex-start;
      }

      &:not(:first-of-type) {
        border-top: 1px solid rgba(170,170,170,.35);
        margin-inline: 25px;
      }
    }
  }

  .MuiPaper-root {
    &::before {
      content: none;
    }
  }

  form {
    .aside__line-container {
      flex-direction: column;
    }

    .aside__line {
      flex-direction: column;

      div {
        width: 100%;
      }

      input {
        border-radius: 3px;
        border-color: rgba(170, 170, 170, .35);
      }

      &:hover {
        input {
          background-color: white !important;
        }
      }
    }

    .button--login {
      margin-top: 25px;
      width: 100%;
      border-radius: 0;
      height: 36px;
      padding: 0;
    }
  }

  ul {
    .nav__submenu-item {
      padding-right: 0 !important;
    }
  }

  .sidebar-icon {
    height: 12px;
    width: 12px;
  }
}

.login-pop-up {
  background-color: white;
  z-index: 10;
}

.button--toggle-login {
  /* style for the toggle button */
  margin: 10px 0;
}

.sidebar {
  z-index: 10000;

}

.side-login{

}