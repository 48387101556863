@font-face {
  font-family: 'Avenir Book 45';
  src: url('../../assets/fonts/AvenirLT-Std-45-Book.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../assets/fonts/Outfit-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../assets/fonts/Outfit-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../../assets/fonts/Outfit-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}