.MuiTableContainer-root {
    box-shadow: unset !important;
}

thead {
    background: #1d6cc1;
    tr {
        th {
            color: white !important;
        }
    }
}

.btn-container {
    width: 80px;
    box-sizing: border-box;
}

.like {
    cursor: pointer;
    transition: .2s all;
    padding: 16px 20px;
    vertical-align: text-top;
    color: blue;

    &:hover {
        color: red;
    }
}

.buy-btn {
    background: #1d6cc1;
    color: white;
    padding: 16px 20px;
    vertical-align: text-top;
    transition: .3s all ease-in;

    &:hover {
        background: black;
    }
}

p.buy-btn.btn-container {
    padding: 2px 0px !important;
}

.tr {
    &:hover {
        background-color: #b7c9dc;
    }
}