.my-page {
    .vehicles-list {
        grid-template-columns: repeat(auto-fit, 180px);


        margin: 30px 0;
    }
    
    .MuiPaper-root {
        border-top: 1px solid rgba(0,0,0,.35);
    }

    .aside__line {
        color: black;
    }

    .infoData {
        background-color: #1d6cc1;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        color: white;
    }
}