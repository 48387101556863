.links-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 202px);
  justify-content: center;
  gap: 50px;

  .link {
    max-width: 202px;
    width: 100%;
    color: #2d2d2d;
    position: relative;
    overflow: hidden;
    height: 202px !important;
    transition: .3s all ease-in;

    &:hover {
      picture {
        transform: scale(1.15);
      }
    }

    &:hover {
      .bg-change {
        background: rgba(45, 45, 45, .65);
      }

      .link-name {
        &::after {
          width: 100%;
          background-color: white;
        }
      }
    }

    .link-name {
      position: absolute;
      z-index: 4;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;

      &::after {
        content: '';
        display: block;
        height: 2px;
        transition: .3s all;
        margin: 5px auto 0;
        width: 0;
      }
    }
  }

  .bg-change {
    position: absolute;
    z-index: 3;
    background: rgba(45, 45, 45, .25);
    height: 100%;
    width: 100%;
    transition: .3s all ease-in;
  }

  picture {
    transition: .3s all ease-in;
  }

  .link-name {
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }

}