.MuiTabs-root {
  height: 37px;
  min-height: unset !important;
}

.MuiButtonBase-root {
  min-height: auto !important;
}

.css-1gsv261 {
  border-bottom: none !important;
}

.RaDatagrid-headerCell {
  color: black !important;
}