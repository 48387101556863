@import '../../styles/abstracts/variables';

.form {
  .field-container {
    display: flex;
    margin-top: 20px;

    @media (max-width: $small) {
      flex-direction: column;
    }
  }
}

label {
  width: 270px;
  padding-right: 25px;
  display: inline-block;
}

.textBox {
  &:not([type='submit']) {
    width: 100%;
    padding: 5px;
  }

  &[type='submit'] {
    display: flex;
    margin: 20px auto 0;
  }
}

.message-input {
  height: 200px;
  width: 100%;
}


.informational {
  padding: 15px;
  background: rgba(170, 170, 170, .2);

  .fa-circle-info {
    margin-right: 12px;
  }
}