/*button.MuiButtonBase-root {*/
/*     background-color: transparent !important;*/
/*     border-right: transparent;*/
/*     color: white;*/
/*     border-radius: 0px 0px 0px 0px;*/
/*     height: 37px;*/
/*}*/

/*button.MuiButtonBase-root {*/

/*    border-right: 0px solid white !important;*/

/*}*/

/*.css-1vq6lce-MuiFormLabel-root-MuiInputLabel-root {*/
/*     color: transparent;*/
/*}*/

/*input:focus::-webkit-input-placeholder { color:transparent; }*/

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root {
    color: rgba(0, 0, 0, 0.87);
}