.my-machine {
  margin-top: 100px;

  h1 {
    margin: 24px auto 0;
    width: fit-content;
    font-size: 32px;
  }

  .vehicle-link {
    position: relative;
    overflow: hidden;
    height: 202px !important;
    transition: .3s all ease-in;

    &:hover {
      .bg-change {
        background: rgba(0,0,0,.33);
      }

      .vehicle-name {
        &::after {
          width: 100%;
          background-color: white;
        }
      }
    }

    .bg-change {
      position: absolute;
      z-index: 3;
      background: rgba(0,0,0,0);
      height: 100%;
      width: 100%;
      transition: .3s all ease-in;
    }

    picture {
      transition: .3s all ease-in;
    }

    &:hover {
      picture {
        transform: scale(1.15);
      }
    }
  }

  .vehicle-name {
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;

    &::after {
      content: '';
      display: block;
      height: 2px;
      transition: .3s all;
      margin: 5px auto 0;
      width: 0;
    }
  }
}

.description-header {
  margin: 0px !important;
  color: #1d6cc1
}

.description {
  font-size: 1.3rem !important;
}

.tech-data-header {
  font-size: 1.2rem
}

.hr-price {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid rgba(170,170,170,.35);
  border-bottom: 1px solid rgba(170,170,170,.35);
}

.in-stock {
  background-color: rgba(170,170,170,.35);
  text-align: center;
  padding: 20px 0;
}